import React, { useState } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <nav className='d-above-700'>
      <div className='nav-bar'>
        <img
          src={'img/Logo.png'}
          alt="Royal Brand"
          className='logo-b'
        />
        <div className='bar-items a'>
          <Link to={'/'} className='link'>
            <div className={`${location.pathname === '/' ? 'bar-item-active' : 'bar-item'}`}>Home</div>
          </Link>
          <Link to={'/about'} className='link'>
            <div className={`${location.pathname === '/about' ? 'bar-item-active' : 'bar-item'}`}>About</div>
          </Link>
          {/* <Link to={'/divisions'} className='link'>
            <div className={`${location.pathname === '/divisions' ? 'bar-item-active' : 'bar-item'}`}>Divisions</div>
          </Link>
          <Link to={'/outlets'} className='link'>
            <div className={`${location.pathname === '/outlets' ? 'bar-item-active' : 'bar-item'}`}>Outlets</div>
          </Link> */}
          <Link to={'/products'} className='link'>
            <div className={`${location.pathname === '/products' ? 'bar-item-active' : 'bar-item'}`}>Products</div>
          </Link>
          <Link to={'/brands'} className='link'>
            <div className={`${location.pathname === '/brands' ? 'bar-item-active' : 'bar-item'}`}>Brands</div>
          </Link>
          {/* <Link to={'/careers'} className='link'>
            <div className={`${location.pathname === '/careers' ? 'bar-item-active' : 'bar-item'}`}>Careers</div>
          </Link> */}
          <Link to={'/contact'} className='link'>
            <div className={`${location.pathname === '/contact' ? 'bar-item-active' : 'bar-item'}`}>Contact Us</div>
          </Link>
        </div>
      </div>
    </nav>

    <div className="d-below-700 a">
    <nav className={isOpen ? 'sidebar open' : 'sidebar'}>
      <ul>
        <Link to={'/'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/' ? 'active' : ''}`}>Home</li>
        </Link>
        <Link to={'/about'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/about' ? 'active' : ''}`}>About</li>
        </Link>
        {/* <Link to={'/divisions'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/divisions' ? 'active' : ''}`}>Divisions</li>
        </Link>
        <Link to={'/outlets'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/outlets' ? 'active' : ''}`}>Outlets</li>
        </Link> */}
        <Link to={'/products'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/products' ? 'active' : ''}`}>Products</li>
        </Link>
        <Link to={'/brands'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/brands' ? 'active' : ''}`}>Brands</li>
        </Link>
        {/* <Link to={'/careers'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/careers' ? 'active' : ''}`}>Careers</li>
        </Link> */}
        <Link to={'/contact'} onClick={toggleSidebar} className='link'>
        <li className={`${location.pathname === '/contact' ? 'active' : ''}`}>Contact Us</li>
        </Link>
      </ul>
    </nav>
    <div className='bar'>
    <img
          src={'img/Logo.png'}
          alt="Royal Brand"
          className='logo'
        />
      <div className='sidebar-btn' onClick={toggleSidebar}>{!isOpen ?<FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faXmark} />}</div>
      </div>
      </div>
      </>
  );
};

export default Sidebar;
